import React, {useEffect, useRef, useState} from 'react'
import {Doughnut} from 'react-chartjs-2'
import {Bar} from 'react-chartjs-2'
import {Chart as ChartJS, registerables} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import styled from 'styled-components'
import PageHeader from "./PageHeader";
import {Button} from "./CommonStylesForms";

export const JumpToPage = styled.input`
  margin: auto 10px auto 0;
  width: 60px;
  font-size: 1em;
  color: ${(props) => props.theme.primary_color};
`

function CredentialsReport(props) {
    ChartJS.register(...registerables);
    const groupedCredentials = props.groupedCredentials ? props.groupedCredentials : []

    const labels = []
    const credentialsData = []
    const [fromDate, setFromDate] = useState(new Date().setDate(-1));
    const [toDate, setToDate] = useState(new Date());
    const changeFromDate = date => setFromDate(date);
    const changeToDate = date => setToDate(date);

    const today = new Date();
    let minDate = new Date().setFullYear(today.getFullYear() - 100);

    groupedCredentials.credential_records.forEach((credential) => {
        labels.push(credential['schema_id'].split(':')[2].toString().replaceAll('_', ' '))
        credentialsData.push(credential['credentialsCount'])
    })

    let totalCredentials = credentialsData.reduce((total,currentItem) =>  total = total + parseInt(currentItem) , 0 )

    const doughnutsChartData = {
        labels: labels,
        datasets: [
            {
                label: '# of Credentials',
                data: credentialsData,
                backgroundColor: [
                    'rgba(60, 60, 60, 0.9)',
                    'rgba(255, 0, 0, 0.9)',
                    'rgba(0, 0, 255, 0.9)',
                    'rgba(60, 179, 113, 0.9)',
                    'rgba(238, 130, 238, 0.9)',
                    'rgba(255, 165, 0, 0.9)',
                    'rgba(106, 90, 205, 0.9)',
                    'rgba(210, 210, 210, 0.9)',
                ],
                borderColor: [
                    'rgba(60, 60, 60, 1)',
                    'rgba(255, 0, 0, 1)',
                    'rgba(0, 0, 255, 1)',
                    'rgba(60, 179, 113, 1)',
                    'rgba(238, 130, 238, 1)',
                    'rgba(255, 165, 0, 1)',
                    'rgba(106, 90, 205, 1)',
                    'rgba(210, 210, 210, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    function filterGroupedCredentials() {
        props.sendRequest('CREDENTIALS', 'GET_ALL_GROUPED', {
            params: {
                fromDate: fromDate,
                toDate: toDate,
            }, // (mikekebert) Mostly empty params, please just give us all the defaults
        })
    }

    return (
        <>
            <PageHeader title={'Credentials Report'}/>
            <div className="datepicker">From</div>
            <DatePicker
                selected={fromDate}
                onChange={changeFromDate}
                minDate={minDate}
                maxDate={today}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
            />
            <div className="datepicker">To</div>
            <DatePicker
                selected={toDate}
                onChange={changeToDate}
                minDate={minDate}
                maxDate={today}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
            />

            <Button className="filter-button" onClick={filterGroupedCredentials}>
                Get reports
            </Button>

            <div className="credentials-stats">
                <table>
                    <thead>
                    <tr>
                        <th>Credentials total:</th>
                        <th>{totalCredentials}</th>
                    </tr>
                    </thead>
                </table>
                <table>
                    <thead>
                    <tr>
                        <th>Credential Type</th>
                        <th>Count</th>
                    </tr>
                    </thead>
                    <tbody>
                    {groupedCredentials.credential_records.map(item => {
                        return (
                            <tr key={item.password}>
                                <td>{item['schema_id'].split(':')[2].toString().replaceAll('_', ' ')}</td>
                                <td>{item['credentialsCount']}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>

            <div>
                <div id="pie-chart">
                    <Doughnut data={doughnutsChartData}/>
                </div>
            </div>
        </>
    )
}

export default CredentialsReport
