import React, { useEffect, useRef, useState } from 'react'

import styled from 'styled-components'
import { useTheme } from 'styled-components'
// import FormContacts from './FormContacts'
import FormQR from './FormQR'
import PageHeader from './PageHeader'
import PageSection from './PageSection'
import { useNotification } from './NotificationProvider'

import { DataTable, DataRow, DataHeader, DataCell } from './CommonStylesTables'
import {
  ActionButton,
  Button,
  SortSelected,
  SubmitBtn,
} from './CommonStylesForms'

import { CanUser } from './CanUser'

export const JumpToPage = styled.input`
  margin: auto 10px auto 0;
  width: 60px;
  font-size: 1em;
  color: ${(props) => props.theme.primary_color};
`

function Contacts(props) {
  const localUser = props.loggedInUserState
  const pagination = props.contacts
    ? props.contacts.params
      ? props.contacts.params
      : {}
    : {}

  const setNotification = useNotification()
  const paginationRef = useRef()

  // (mikekebert) paginationSort is an array containing arrays of sort field and direction,
  // e.g., [['updated_at', 'DESC'], ['name', 'ASC']]
  const [paginationPageSize, setPaginationPageSize] = useState(
    pagination.pageSize
  )
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(
    pagination.currentPage ? pagination.currentPage : 1
  )
  const [paginationPageCount, setPaginationPageCount] = useState(
    pagination.pageCount
  )
  const [paginationItemCount, setPaginationItemCount] = useState(
    pagination.itemCount
  )

  const [paginationJumpPage, setPaginationJumpPage] = useState(
    pagination.currentPage ? pagination.currentPage : 1
  )

  const [sortSelected, setSortSelected] = useState([['updated_at', 'DESC']])
  const [paginationSort, setPaginationSort] = useState(sortSelected)

  useEffect(() => {
    setPaginationSort(sortSelected)
    setPaginationPageSize(pagination.pageSize)
    setPaginationCurrentPage(pagination.currentPage)
    setPaginationPageCount(pagination.pageCount)
    setPaginationItemCount(pagination.itemCount)

    setPaginationJumpPage(pagination.currentPage)
  }, [props.contacts])

  const [contactModalIsOpen, setContactModalIsOpen] = useState(false)

  const closeContactModal = () => setContactModalIsOpen(false)

  function openContact(history, id) {
    if (history !== undefined) {
      history.push('/contacts/' + id)
    }
  }

  const history = props.history

  useEffect(() => {
    if (props.connectionReuse) {
      const message = `Connection reused for ${props.connectionReuse.connection_id}`
      setNotification(message, 'notice')
      props.clearConnectionReuse()
    }
  }, [props.connectionReuse])

  const contacts = props.contacts
    ? props.contacts.rows
      ? props.contacts.rows
      : []
    : []

  function nextPage() {
    const paginationForm = new FormData(paginationRef.current)

    let nextPage = Math.min(
      parseInt(paginationCurrentPage) + 1,
      paginationPageCount
    )

    setPaginationCurrentPage(nextPage)
    setPaginationJumpPage(nextPage)

    props.sendRequest('CONTACTS', 'GET_ALL', {
      params: {
        sort: paginationSort,
        pageSize: paginationPageSize,
        currentPage: nextPage,
        pageCount: Math.ceil(contacts.count / paginationPageSize),
        itemCount: paginationItemCount,
      },
      additional_tables: ['Demographic', 'Passport'],
    })
  }
  function prevPage() {
    const paginationForm = new FormData(paginationRef.current)

    let prevPage = Math.max(parseInt(paginationCurrentPage) - 1, 1)

    setPaginationCurrentPage(prevPage)
    setPaginationJumpPage(prevPage)

    props.sendRequest('CONTACTS', 'GET_ALL', {
      params: {
        sort: paginationSort,
        pageSize: paginationPageSize,
        currentPage: prevPage,
        pageCount: Math.ceil(contacts.count / paginationPageSize),
        itemCount: paginationItemCount,
      },
      additional_tables: ['Demographic', 'Passport'],
    })
  }

  function jumpToPage(e) {
    e.preventDefault()

    const paginationForm = new FormData(paginationRef.current)

    let jumpToPage = paginationForm.get('jumpPage')

    if (jumpToPage > paginationPageCount) jumpToPage = paginationPageCount
    if (jumpToPage < 1) jumpToPage = 1

    setPaginationCurrentPage(jumpToPage)
    setPaginationJumpPage(jumpToPage)

    props.sendRequest('CONTACTS', 'GET_ALL', {
      params: {
        sort: paginationSort,
        pageSize: paginationPageSize,
        currentPage: jumpToPage,
        pageCount: Math.ceil(contacts.count / paginationPageSize),
        itemCount: paginationItemCount,
      },
      additional_tables: ['Demographic', 'Passport'],
    })
  }

  const handleJumpPageChange = (e) => {
    setPaginationJumpPage(e.target.value)
  }

  const contactRows = contacts.map((contact) => {
    return (
      <DataRow
        key={contact.contact_id}
        onClick={() => {
          openContact(history, contact.contact_id, contact)
        }}
      >
        <DataCell>{contact.label}</DataCell>
        <DataCell>
          {contact.Demographic !== null && contact.Demographic !== undefined
            ? contact.Demographic.mpid || ''
            : ''}
        </DataCell>
        <DataCell>{contact.Connections[0].state}</DataCell>
        <DataCell>{new Date(contact.updated_at).toLocaleString()}</DataCell>
      </DataRow>
    )
  })

  let paginationUI = (
    <div style={{ overflow: 'hidden', paddingBottom: '3px' }}>
      <form
        onSubmit={jumpToPage}
        ref={paginationRef}
        style={{ float: 'right' }}
      >
        <JumpToPage
          onChange={handleJumpPageChange}
          type="text"
          name="jumpPage"
          value={paginationJumpPage}
        />
        / {paginationPageCount} pages
        <SubmitBtn style={{ marginLeft: '10px' }}>GO</SubmitBtn>
      </form>
      <div style={{ float: 'right', paddingTop: '15px' }}>
        <Button
          disabled={paginationCurrentPage > 1 ? false : true}
          style={
            paginationCurrentPage > 1
              ? { marginRight: '10px' }
              : { visibility: 'hidden', marginRight: '10px' }
          }
          onClick={prevPage}
        >
          Prev
        </Button>
        <Button
          disabled={paginationCurrentPage < paginationPageCount ? false : true}
          style={
            paginationCurrentPage < paginationPageCount
              ? { marginRight: '10px' }
              : { visibility: 'hidden', marginRight: '10px' }
          }
          onClick={nextPage}
        >
          Next
        </Button>
      </div>
    </div>
  )

  let handleSortChoice = async (e) => {
    e.preventDefault()
    let sortChoices = [
      ['updated_at', 'DESC'],
      ['updated_at', 'ASC'],
      ['label', 'ASC'],
      ['label', 'DESC'],
    ]
    let sorted = sortChoices[e.target.value]
    await setSortSelected([sortChoices[e.target.value]])

    const paginationForm = new FormData(paginationRef.current)

    props.sendRequest('CONTACTS', 'GET_ALL', {
      params: {
        sort: [sorted],
        pageSize: paginationPageSize,
        currentPage: 1,
        pageCount: Math.ceil(contacts.count / paginationPageSize),
        itemCount: paginationItemCount,
      },
      additional_tables: ['Demographic', 'Passport'],
    })
  }

  let sortSelect = (
    <span style={{ float: 'right' }}>
      <SortSelected name="sortSelected" onChange={handleSortChoice} style={{}}>
        <option value={0}>Newest to Oldest</option>
        <option value={1}>Oldest to Newest</option>
        <option value={2}>Contact: A - Z</option>
        <option value={3}>Contact: Z - A</option>
      </SortSelected>
    </span>
  )

  return (
    <>
      <div id="contacts">
        <PageHeader title={'Contacts'} />
        <PageSection>
          <DataTable>
            <thead>
              <DataRow>
                <DataHeader>Contact Name</DataHeader>
                <DataHeader></DataHeader>
                <DataHeader>Connection Status</DataHeader>
                <DataHeader>Created At{sortSelect}</DataHeader>
              </DataRow>
            </thead>
            <tbody>{contactRows}</tbody>
          </DataTable>
          {paginationUI}
        </PageSection>
        <CanUser
          user={localUser}
          perform="contacts:create"
          yes={() => (
            <ActionButton
              title="Add a New Contact"
              onClick={() => {
                setContactModalIsOpen((o) => !o)
                props.sendRequest('INVITATIONS', 'CREATE', {
                  alias: 'Contact',
                  invitationMode: 'once',
                  accept: 'auto',
                  invitationRole: 'holder',
                  invitationLabel: 'Holder',
                  invitationStatus: 'active',
                  invitationDescription: 'Invited from Contacts Page',
                  invitationActiveStartingAt: undefined,
                  invitationActiveEndingAt: undefined,
                  usesAllowed: undefined,
                })
              }}
            >
              +
            </ActionButton>
          )}
        />
        <FormQR
          contactModalIsOpen={contactModalIsOpen}
          closeContactModal={closeContactModal}
          QRCodeURL={props.QRCodeURL}
        />
        {/*<FormContacts
          contactModalIsOpen={contactModalIsOpen}
          closeContactModal={closeContactModal}
          submitContact={submitNewContact}
        />*/}
      </div>
    </>
  )
}

export default Contacts
