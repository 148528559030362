import React from 'react'

import {
  Actions,
  StyledPopup,
  SubmitBtnModal,
  Modal,
  ModalHeader,
} from './CommonStylesForms'

export default function FormRevokeCredential(props) {
  return (
    <StyledPopup closeOnDocumentClick={false} open={props.revokeModalIsOpen}>
      <Modal className="modal">
        <ModalHeader>
          Are you sure you want to revoke your credential?
        </ModalHeader>
        <form id="form" onSubmit={props.handleSubmit}>
          <Actions>
            <SubmitBtnModal type="submit">Yes</SubmitBtnModal>
            <SubmitBtnModal onClick={() => props.closeRevokeModal()}>
              No
            </SubmitBtnModal>
          </Actions>
        </form>
      </Modal>
    </StyledPopup>
  )
}
