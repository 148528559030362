import React, { useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

import FormContacts from './FormContacts'
import FormPassport from './FormPassport'
import FormTestResult from './FormTestResult'
import FormDriversLicense from './FormDriversLicense'
import FormLevelOnePhone from './FormLevelOnePhone'
import FormValidatedEmail from './FormValidatedEmail'
import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'
import { useNotification } from './NotificationProvider'

import { CanUser } from './CanUser'

import {
  DataTable,
  DataRow,
  DataHeader,
  DataCell,
  AttributeTable,
  AttributeRow,
} from './CommonStylesTables'

const EditContact = styled.button`
  float: right;
  padding: 10px;
  color: ${(props) => props.theme.text_light};
  border: none;
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: ${(props) => props.theme.primary_color};
`

const IssueCredential = styled.button`
  float: right;
  padding: 10px;
  color: ${(props) => props.theme.text_light};
  border: none;
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: ${(props) => props.theme.primary_color};
`

const MessageInput = styled.input`
  float: left;
  padding: 5px 20px;
  font-size: 14px;
  background-color: #f5f5f5;
  border: 1px solid #dedede;
  height: 36px;
  width: calc(100% - 120px);
  margin-bottom: 20px;
`

const SendMessage = styled.button`
  float: right;
  padding: 10px;
  color: ${(props) => props.theme.text_light};
  border: none;
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: ${(props) => props.theme.primary_color};
`

const ChatBox = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #dedede;
  height: 30vh;
  margin-bottom: 20px;
  overflow: scroll;
`

const MessageReceived = styled.div`
  padding: 20px;
  margin: 20px;
  display: inline-block;
  position: relative;
  width: 200px;
  height: auto;
  background-color: #dedede;
  :after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -20px;
    right: auto;
    top: 0px;
    bottom: auto;
    border: 22px solid;
    border-color: #dedede transparent transparent transparent;
  }
  small {
    font-size: 11px;
    float: right;
  }
`

const MessageSent = styled.div`
  padding: 20px;
  margin: 20px;
  display: block;
  float: right;
  position: relative;
  width: 200px;
  height: auto;
  background-color: #82ceec;
  :after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -20px;
    top: 0px;
    bottom: auto;
    border: 22px solid;
    border-color: #82ceec transparent transparent transparent;
  }
  small {
    font-size: 11px;
    float: right;
  }
`

const MessagesEnd = styled.div`
  float: left;
  clear: both;
`

function Contact(props) {
  const localUser = props.loggedInUserState
  const privileges = props.privileges
  const [messageContent, setMessageContent] = useState('')
  const [messagesList, setMessagesList] = useState('')
  const [gotTextMessages, setGotTextMessages] = useState(false)

  var localList = props.messagesList
  if (
    !localList.includes(props.incomingMessage) &&
    props.incomingMessage != null
  ) {
    localList.push(props.incomingMessage)
  }

  const messagesItems = localList.map((message) => {
    if (message.state === 'received')
      return (
        <div className="clearfix">
          <MessageReceived>
            {message.content} <br></br>
            <small>{message.created_at}</small>
          </MessageReceived>
        </div>
      )
    else
      return (
        <div className="clearfix">
          <MessageSent>
            {message.content} <br></br>
            <small>{message.created_at}</small>
          </MessageSent>
        </div>
      )
  })

  // Accessing notification context
  const setNotification = useNotification()
  const history = props.history
  let contact = props.contact

  useEffect(() => {
    if (props.connectionReuse) {
      const message = `Connection reused for ${props.connectionReuse.connection_id}`
      setNotification(message, 'notice')
      props.clearConnectionReuse()
    }
  }, [props.connectionReuse])

  useEffect(() => {
    if (props.contactId != '') {
      let data = {
        contact_id: props.contactId,
        additional_tables: ['Demographic', 'Passport'],
      }
      props.sendRequest('CONTACTS', 'GET', data)
    }
  }, [history])

  useEffect(() => {
    setContactSelected(contact)
    if (
      contactSelected.Connections !== null &&
      contactSelected.Connections !== undefined
        ? contactSelected.Connections[0].connection_id || ''
        : ''
    ) {
      let connectionID = props.contact.Connections[0].connection_id
    }
  }, [contact])

  useEffect(() => {
    scrollToBottom()
  })

  function openCredential(history, id) {
    if (history !== undefined) {
      history.push('/credentials/' + id)
    }
  }

  // Modal state
  const [contactModalIsOpen, setContactModalIsOpen] = useState(false)
  const [passportModalIsOpen, setPassportModalIsOpen] = useState(false)
  const [driversLicenseModalIsOpen, setDriversLicenseModalIsOpen] = useState(
    false
  )
  const [levelOnePhoneModalIsOpen, setLevelOnePhoneModalIsOpen] = useState(
    false
  )
  const [validatedEmailModalIsOpen, setValidatedEmailModalIsOpen] = useState(
    false
  )

  const closeContactModal = () => setContactModalIsOpen(false)
  const closePassportModal = () => setPassportModalIsOpen(false)
  const closeDriversLicenseModal = () => setDriversLicenseModalIsOpen(false)
  const closeValidateEmailModal = () => setValidatedEmailModalIsOpen(false)
  const closeLevelOnePhoneModal = () => setLevelOnePhoneModalIsOpen(false)

  const [contactSelected, setContactSelected] = useState(contact)

  let demographicData = ''
  let passportData = ''

  if (
    contactSelected !== undefined &&
    contactSelected.Passport !== null &&
    contactSelected.Passport !== undefined
  ) {
    let rawImage = contactSelected.Passport.photo

    const handleImageSrc = (rawImage) => {
      let bytes = new Uint8Array(rawImage)
      bytes = Buffer.from(rawImage).toString('base64')
      // Check the MIME type
      // let result = null
      // if (atob(bytes).charAt(0) === 'i')
      // result = `data:image/png;base64,${atob(bytes)}`
      // else if (atob(bytes).charAt(0) === '/')
      // result = `data:image/jpeg;base64,${atob(bytes)}`
      // else if (atob(bytes).charAt(0) === 'R')
      // result = `data:image/gif;base64,${atob(bytes)}`
      // else if (atob(bytes).charAt(0) === 'U')
      // result = `data:image/webp;base64,${atob(bytes)}`
      // else
      let result = atob(bytes)
      return result
    }

    let passportPhoto = handleImageSrc(rawImage)
    passportData = (
      <div>
        <h2>Passport Information</h2>
        <AttributeTable>
          <tbody>
            <AttributeRow>
              <th>Passport Number:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.passport_number || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Surname:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.surname || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Given Name(s):</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.given_names || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Sex:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.sex || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Date of Birth:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.date_of_birth || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Place of Birth:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.place_of_birth || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Nationality:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.nationality || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Date of Issue:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.date_of_issue || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Date of Expiration:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.date_of_expiration || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Type:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.type || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Code:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.code || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Authority:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.authority || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Photo:</th>
              <td></td>
            </AttributeRow>
          </tbody>
        </AttributeTable>
        <img src={passportPhoto} alt="Error" />
      </div>
    )
  }

  function sendTextMessage() {
    try {
      props.sendRequest('BASIC_MESSAGES', 'SEND_MESSAGE', {connection_id:contactSelected.Connections[0].connection_id, content:messageContent})

      setMessageContent('')
    } catch (error) {
      console.error('Error Sending text message')
      throw error
    }
  }

  function getAllTextMessages() {
    try {
      props.sendRequest('BASIC_MESSAGES', 'GET_MESSAGES', {connection_id:contactSelected.Connections[0].connection_id})
    } catch (error) {
      console.error('Error getting messages')
      throw error
    }

    setGotTextMessages(true)
  }

  const messagesEndRef = useRef(null)

  function scrollToBottom() {
    messagesEndRef.current.scrollIntoView()
  }

  function updateContact(updatedDemographic, e) {
    e.preventDefault()
    const Demographic = {
      Demographic: { ...updatedDemographic },
    }

    props.sendRequest('DEMOGRAPHICS', 'UPDATE_OR_CREATE', updatedDemographic)

    setNotification('Contact was updated!', 'notice')

    setContactSelected({ ...contactSelected, ...Demographic })
  }

  // Submits the credential form and shows notification
  function submitNewCredential(newCredential, e) {
    e.preventDefault()

    props.sendRequest('CREDENTIALS', 'ISSUE_USING_SCHEMA', newCredential)

    setNotification('Credential was successfully offered!', 'notice')
  }

  const credentialRows = props.credentials.map((credential_record) => {
    if (
      contactSelected.Connections &&
      contactSelected.Connections[0].connection_id ===
        credential_record.connection_id
    ) {
      const credentialID = credential_record.credential_exchange_id
      const credentialState = credential_record.state.replaceAll('_', ' ') || ''
      const dateCreated =
        new Date(credential_record.created_at).toLocaleString() || ''

      let credentialName = ''
      if (
        credential_record.credential_proposal_dict !== null &&
        credential_record.credential_proposal_dict !== undefined
      ) {
        credentialName = credential_record.credential_proposal_dict.schema_name.replaceAll(
          '_',
          ' '
        )
      }
      return (
        <DataRow
          key={credentialID}
          onClick={() => {
            openCredential(history, credentialID)
          }}
        >
          <DataCell>{credentialName}</DataCell>
          <DataCell className="title-case">{credentialState}</DataCell>
          <DataCell>{dateCreated}</DataCell>
        </DataRow>
      )
    }
  })
  // }


  if(!gotTextMessages && contactSelected != null && contactSelected.Connections != null && contactSelected.Connections.size > 0) {

    getAllTextMessages()
  }

  return (
    <>
      <div id="contact">
        <PageHeader
          title={'Contact Details: ' + (contactSelected.label || '')}
        />
        <PageSection>
          <CanUser
            user={localUser}
            perform="contacts:update"
            yes={() => (
              <EditContact onClick={() => setContactModalIsOpen((o) => !o)}>
                Edit
              </EditContact>
            )}
          />
          <h2>General Information</h2>
          <AttributeTable>
            <tbody>
              <AttributeRow>
                <th>Contact ID:</th>
                <td>{contactSelected.contact_id || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Connection Status:</th>
                <td>
                  {contactSelected.Connections !== undefined
                    ? contactSelected.Connections[0].state || ''
                    : ''}
                </td>
              </AttributeRow>
            </tbody>
          </AttributeTable>

          <h2>Contact Information</h2>
          <AttributeTable>
            <tbody>
              <AttributeRow>
                <th>Name:</th>
                <td>{contactSelected.label || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>User ID:</th>
                <td>
                  {contactSelected.Connections !== undefined
                    ? contactSelected.Connections[0].user_id || ''
                    : ''}
                </td>
              </AttributeRow>
              {/* <AttributeRow>
                <th>Email:</th>
                <td>
                  {contactSelected.Demographic !== null &&
                  contactSelected.Demographic !== undefined
                    ? contactSelected.Demographic.email || ''
                    : ''}
                </td>
              </AttributeRow> */}
            </tbody>
          </AttributeTable>
          {passportData}
        </PageSection>
        <PageSection>
          <CanUser
            user={localUser}
            perform="credentials:issue"
            yes={() => (
              <IssueCredential
                onClick={() =>
                  privileges && privileges.includes('issue_level_1_email')
                    ? setValidatedEmailModalIsOpen((o) => !o)
                    : setNotification(
                        "Error: you don't have the right privileges",
                        'error'
                      )
                }
              >
                Issue Email Credential
              </IssueCredential>
            )}
          />
          <CanUser
            user={localUser}
            perform="credentials:issue"
            yes={() => (
              <IssueCredential
                onClick={() =>
                  privileges && privileges.includes('issue_level_1_phone')
                    ? setLevelOnePhoneModalIsOpen((o) => !o)
                    : setNotification(
                        "Error: you don't have the right privileges",
                        'error'
                      )
                }
              >
                Issue Level 1 Phone Credential
              </IssueCredential>
            )}
          />
          <CanUser
            user={localUser}
            perform="credentials:issue"
            yes={() => (
              <IssueCredential
                onClick={() =>
                  privileges && privileges.includes('issue_passport')
                    ? setPassportModalIsOpen((o) => !o)
                    : setNotification(
                        "Error: you don't have the right privileges",
                        'error'
                      )
                }
              >
                Issue Passport Credential
              </IssueCredential>
            )}
          />
          <CanUser
            user={localUser}
            perform="credentials:issue"
            yes={() => (
              <IssueCredential
                onClick={() =>
                  privileges && privileges.includes('issue_drivers_license')
                    ? setDriversLicenseModalIsOpen((o) => !o)
                    : setNotification(
                        "Error: you don't have the right privileges",
                        'error'
                      )
                }
              >
                Issue Drivers License Credential
              </IssueCredential>
            )}
          />
          <DataTable>
            <thead>
              <DataRow>
                <DataHeader>Credential</DataHeader>
                <DataHeader>Status</DataHeader>
                <DataHeader>Date Issued</DataHeader>
              </DataRow>
            </thead>
            <tbody>{credentialRows}</tbody>
          </DataTable>
        </PageSection>
        <PageSection>
          <div className="row">
            <div className="column clearfix">
              <h1> Chat with user </h1>
              <ChatBox>
                {' '}
                {messagesItems} <MessagesEnd ref={messagesEndRef}></MessagesEnd>
              </ChatBox>
              <form className="form" id="myForm">
                <MessageInput
                  type="text"
                  id="message-field"
                  name="message-field"
                  value={messageContent}
                  onChange={(e) => setMessageContent(e.target.value)}
                ></MessageInput>
              </form>
              <SendMessage onClick={sendTextMessage}>Send Message</SendMessage>
            </div>
          </div>
        </PageSection>
        <FormContacts
          contactSelected={contactSelected}
          contactModalIsOpen={contactModalIsOpen}
          closeContactModal={closeContactModal}
          submitContact={updateContact}
        />
        <FormValidatedEmail
          contactSelected={contactSelected}
          credentialModalIsOpen={validatedEmailModalIsOpen}
          closeCredentialModal={closeValidateEmailModal}
          submitCredential={submitNewCredential}
          schemas={props.schemas}
        />
        <FormLevelOnePhone
          contactSelected={contactSelected}
          credentialModalIsOpen={levelOnePhoneModalIsOpen}
          closeCredentialModal={closeLevelOnePhoneModal}
          submitCredential={submitNewCredential}
          schemas={props.schemas}
        />
        <FormPassport
          contactSelected={contactSelected}
          credentialModalIsOpen={passportModalIsOpen}
          closeCredentialModal={closePassportModal}
          submitCredential={submitNewCredential}
          schemas={props.schemas}
        />

        <FormDriversLicense
          contactSelected={contactSelected}
          credentialModalIsOpen={driversLicenseModalIsOpen}
          closeCredentialModal={closeDriversLicenseModal}
          submitCredential={submitNewCredential}
          schemas={props.schemas}
        />
      </div>
    </>
  )
}

export default Contact
