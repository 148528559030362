import React, { useRef } from 'react'

import { DateTime } from 'luxon'

import {
  StyledPopup,
  InputBox,
  Modal,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
  ModalLabel,
  InputFieldModal,
  TextItem,
} from './CommonStylesForms'

function FormLevelOnePhone(props) {
  const credentialForm = useRef(null)
  const dateValidated = DateTime.local()

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(credentialForm.current)

    let attributes = [
      {
        name: 'phone',
        value: form.get('phone'),
      },
      {
        name: 'national_format',
        value: form.get('national_format'),
      },
      {
        name: 'name',
        value: form.get('name'),
      },
      {
        name: 'country_code',
        value: form.get('country_code'),
      },
      {
        name: 'carrier_mobile_country_code',
        value: form.get('carrier_mobile_country_code'),
      },
      {
        name: 'carrier_mobile_network_code',
        value: form.get('carrier_mobile_network_code'),
      },
      {
        name: 'carrier_name',
        value: form.get('carrier_name'),
      },
      {
        name: 'carrier_type',
        value: form.get('carrier_type'),
      },
      {
        name: 'date_validated',
        value: Math.floor(dateValidated.ts / 1000).toString(),
      },
      {
        name: 'identity_image',
        value: form.get('identity_image'),
        'mime-type': 'text/hashlink',
      },
    ]

    let schema = props.schemas.SCHEMA_LEVEL_1_PHONE
    let schemaParts = schema.split(':')

    let newCredential = {
      connectionID: props.contactSelected.Connections[0].connection_id,
      schemaID: schema,
      schemaVersion: schemaParts[3],
      schemaName: schemaParts[2],
      schemaIssuerDID: schemaParts[0],
      comment: 'Level 1 Phone',
      attributes: attributes,
    }

    props.submitCredential(newCredential, e)
    props.closeCredentialModal()
  }

  function closeModal() {
    props.closeCredentialModal()
  }

  return (
    <StyledPopup
      open={props.credentialModalIsOpen}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <Modal className="modal">
        <ModalHeader>Issue Level 1 Phone Credential</ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            <form onSubmit={handleSubmit} ref={credentialForm}>
              <InputBox>
                <ModalLabel htmlFor="phone">Phone</ModalLabel>
                <InputFieldModal type="text" name="phone" id="phone" />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="national_format">
                  National Format
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="national_format"
                  id="national_format"
                  placeholder=""
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="name">Name</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="country_code">Country Code</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="country_code"
                  id="country_code"
                  placeholder=""
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="carrier_mobile_country_code">
                  Carrier Mobile Country Code
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="carrier_mobile_country_code"
                  id="carrier_mobile_country_code"
                  placeholder=""
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="carrier_mobile_network_code">
                  Carrier Mobile Network Code
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="carrier_mobile_network_code"
                  id="carrier_mobile_network_code"
                  placeholder=""
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="carrier_name">Carrier Name</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="carrier_name"
                  id="carrier_name"
                  placeholder=""
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="carrier_type">Carrier Type</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="carrier_type"
                  id="carrier_type"
                  placeholder=""
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="date_validated">Date Validated</ModalLabel>
                <TextItem
                  type="date"
                  name="date_validated"
                  id="date_validated"
                  placeholder=""
                >
                  {dateValidated.toLocaleString(DateTime.DATETIME_SHORT)}
                </TextItem>
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="identity_image">Image Url</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="identity_image"
                  id="identity_image"
                  placeholder=""
                />
              </InputBox>
              <Actions>
                <CancelBtn type="button" onClick={closeModal}>
                  Cancel
                </CancelBtn>
                <SubmitBtnModal type="submit">Submit</SubmitBtnModal>
              </Actions>
            </form>
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={closeModal}>&times;</CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default FormLevelOnePhone
