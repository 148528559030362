import React, { useRef } from 'react'

import { DateTime } from 'luxon'

import {
  StyledPopup,
  InputBox,
  Modal,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
  ModalLabel,
  InputFieldModal,
  TextItem,
} from './CommonStylesForms'

function FormDriversLicense(props) {
  const credentialForm = useRef(null)

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(credentialForm.current)

    let attributes = [
      {
        name: 'document_type_indicator',
        value: form.get('document_type_indicator') || '',
      },
      {
        name: 'surname',
        value: form.get('surname') || '',
      },
      {
        name: 'given_names',
        value: form.get('given_names') || '',
      },
      {
        name: 'date_of_birth',
        value:
          Math.floor(
            DateTime.fromISO(form.get('date_of_birth')).ts / 1000
          ).toString() || '',
      },
      {
        name: 'date_of_issue',
        value:
          Math.floor(
            DateTime.fromISO(form.get('date_of_issue')).ts / 1000
          ).toString() || '',
      },
      {
        name: 'date_of_expiration',
        value:
          Math.floor(
            DateTime.fromISO(form.get('date_of_expiration')).ts / 1000
          ).toString() || '',
      },
      {
        name: 'authority',
        value: form.get('authority') || '',
      },
      {
        name: 'customer_identifier',
        value: form.get('customer_identifier') || '',
      },
      {
        name: 'document_discriminator',
        value: form.get('document_discriminator') || '',
      },
      {
        name: 'portrait',
        value: '',
      },
      {
        name: 'signature',
        value: '',
      },
      {
        name: 'street_address',
        value: form.get('street_address') || '',
      },
      {
        name: 'city',
        value: form.get('city') || '',
      },
      {
        name: 'state_province_region',
        value: form.get('state_province_region') || '',
      },
      {
        name: 'postal_code',
        value: form.get('postal_code') || '',
      },
      {
        name: 'country',
        value: form.get('country') || '',
      },
      {
        name: 'vehicle_classifications',
        value: form.get('vehicle_classifications') || '',
      },
      {
        name: 'endorsements',
        value: form.get('endorsements') || '',
      },
      {
        name: 'restrictions',
        value: form.get('restrictions') || '',
      },
      {
        name: 'sex',
        value: form.get('sex') || '',
      },
      {
        name: 'height_inches',
        value: form.get('height_inches') || '',
      },
      {
        name: 'height_cm',
        value: form.get('height_cm') || '',
      },
      {
        name: 'eye_color',
        value: form.get('eye_color') || '',
      },
      {
        name: 'hair_color',
        value: form.get('hair_color') || '',
      },
      {
        name: 'place_of_birth',
        value: form.get('place_of_birth') || '',
      },
      {
        name: 'category_date_of_first_issue',
        value:
          Math.floor(
            DateTime.fromISO(form.get('category_date_of_first_issue')).ts / 1000
          ).toString() || '',
      },
      {
        name: 'category_date_of_expiry',
        value:
          Math.floor(
            DateTime.fromISO(form.get('category_date_of_expiry')).ts / 1000
          ).toString() || '',
      },
      {
        name: 'weight_pounds',
        value: form.get('weight_pounds') || '',
      },
      {
        name: 'weight_kilograms',
        value: form.get('weight_kilograms') || '',
      },
      {
        name: 'race',
        value: form.get('race') || '',
      },
      {
        name: 'audit_information',
        value: form.get('audit_information') || '',
      },
      {
        name: 'issuing_jurisdiction',
        value: form.get('issuing_jurisdiction') || '',
      },
      {
        name: 'organ_donor',
        value: form.get('organ_donor') || '',
      },
      {
        name: 'veteran_indicator',
        value: form.get('veteran_indicator') || '',
      },
      {
        name: 'compliance_type',
        value: form.get('compliance_type') || '',
      },
      {
        name: 'card_revision_date',
        value:
          Math.floor(
            DateTime.fromISO(form.get('lab_observation_date_time')).ts / 1000
          ).toString() || '',
      },
      {
        name: 'hazmat_endorsement_expiration_date',
        value:
          Math.floor(
            DateTime.fromISO(form.get('lab_observation_date_time')).ts / 1000
          ).toString() || '',
      },
      {
        name: 'limited_duration_document_indicator',
        value: form.get('limited_duration_document_indicator') || '',
      },
    ]

    let schema = props.schemas.SCHEMA_DRIVERS_LICENSE
    let schemaParts = schema.split(':')

    let newCredential = {
      connectionID: props.contactSelected.Connections[0].connection_id,
      schemaID: schema,
      schemaVersion: schemaParts[3],
      schemaName: schemaParts[2],
      schemaIssuerDID: schemaParts[0],
      comment: 'Drivers License',
      attributes: attributes,
    }

    props.submitCredential(newCredential, e)
    props.closeCredentialModal()
  }

  function closeModal() {
    props.closeCredentialModal()
  }

  return (
    <StyledPopup
      open={props.credentialModalIsOpen}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <Modal className="modal">
        <ModalHeader>Issue Drivers License Credential</ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            <form onSubmit={handleSubmit} ref={credentialForm}>
              <InputBox>
                <ModalLabel htmlFor="type">Document Type Indicator</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="document_type_indicator"
                  id="document_type_indicator"
                  placeholder="Document Type"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="surname">Surname</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="surname"
                  id="surname"
                  placeholder="Last Name / Surname"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="given_names">Given Names</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="given_names"
                  id="given_names"
                  placeholder="First and Middle Names"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="date_of_birth">Date of Birth</ModalLabel>
                <InputFieldModal
                  type="date"
                  name="date_of_birth"
                  id="date_of_birth"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="date_of_issue">Date of Issue</ModalLabel>
                <InputFieldModal
                  type="date"
                  name="date_of_issue"
                  id="date_of_issue"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="date_of_expiration">
                  Date of Expiration
                </ModalLabel>
                <InputFieldModal
                  type="date"
                  name="date_of_expiration"
                  id="date_of_expiration"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="authority">Authority</ModalLabel>
                <InputFieldModal type="text" name="authority" id="authority" />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="customer_identifier">
                  Customer Identifier
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="customer_identifier"
                  id="customer_identifier"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="document_discriminator">
                  Document Discriminator
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="document_discriminator"
                  id="document_discriminator"
                />
              </InputBox>
              {/* <InputBox>
                <ModalLabel htmlFor="portrait">Portrait</ModalLabel>
                <InputFieldModal type="text" name="portrait" id="portrait" />
                {portrait}
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="signature">Signature</ModalLabel>
                <InputFieldModal type="text" name="signature" id="signature" />
                {signature}
              </InputBox> */}
              <InputBox>
                <ModalLabel htmlFor="street_address">Street Address</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="street_address"
                  id="street_address"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="city">City</ModalLabel>
                <InputFieldModal type="text" name="city" id="city" />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="state_province_region">
                  State / Province / Region
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="state_province_region"
                  id="state_province_region"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="postal_code">Postal Code</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="postal_code"
                  id="postal_code"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="vehicle_classifications">
                  Vehicle Classifications
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="vehicle_classifications"
                  id="vehicle_classifications"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="endorsements">Endorsements</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="endorsements"
                  id="endorsements"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="restrictions">Restrictions</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="restrictions"
                  id="restrictions"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="sex">Sex</ModalLabel>
                <InputFieldModal type="text" name="sex" id="sex" />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="height_inches">Height (inches)</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="height_inches"
                  id="height_inches"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="height_cm">Height (cm)</ModalLabel>
                <InputFieldModal type="text" name="height_cm" id="height_cm" />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="eye_color">Eye Color</ModalLabel>
                <InputFieldModal type="text" name="eye_color" id="eye_color" />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="hair_color">Hair Color</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="hair_color"
                  id="hair_color"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="place_of_birth">Place of Birth</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="place_of_birth"
                  id="place_of_birth"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="category_date_of_first_issue">
                  Category Date of First Issue
                </ModalLabel>
                <InputFieldModal
                  type="date"
                  name="category_date_of_first_issue"
                  id="category_date_of_first_issue"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="category_date_of_expiry">
                  Category Date of Expiry
                </ModalLabel>
                <InputFieldModal
                  type="date"
                  name="category_date_of_expiry"
                  id="category_date_of_expiry"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="weight_pounds">Weight (pounds)</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="weight_pounds"
                  id="weight_pounds"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="weight_kilograms">
                  Weight (kilograms)
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="weight_kilograms"
                  id="weight_kilograms"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="race">Race</ModalLabel>
                <InputFieldModal type="text" name="race" id="race" />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="audit_information">
                  Audit Information
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="audit_information"
                  id="audit_information"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="issuing_jurisdiction">
                  Issuing Jurisdiction
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="issuing_jurisdiction"
                  id="issuing_jurisdiction"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="organ_donor">Organ Donor</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="organ_donor"
                  id="organ_donor"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="veteran_indicator">
                  Veteran Indicator
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="veteran_indicator"
                  id="veteran_indicator"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="compliance_type">
                  Compliance Type
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="compliance_type"
                  id="compliance_type"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="card_revision_date">
                  Card Revision Date
                </ModalLabel>
                <InputFieldModal
                  type="date"
                  name="card_revision_date"
                  id="card_revision_date"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="hazmat_endorsement_expiration_date">
                  Hazmat Endorsement Expiration Date
                </ModalLabel>
                <InputFieldModal
                  type="date"
                  name="hazmat_endorsement_expiration_date"
                  id="hazmat_endorsement_expiration_date"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="hazmat_endorsement_expiration_date">
                  Limited Duration Document Indicator
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="hazmat_endorsement_expiration_date"
                  id="hazmat_endorsement_expiration_date"
                />
              </InputBox>
              <Actions>
                <CancelBtn type="button" onClick={closeModal}>
                  Cancel
                </CancelBtn>
                <SubmitBtnModal type="submit">Submit</SubmitBtnModal>
              </Actions>
            </form>
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={closeModal}>&times;</CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default FormDriversLicense
