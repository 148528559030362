import styled from 'styled-components'

// Text alignments
export const TextAlignCenter = styled.div`
  text-align: center;
`

export const TextAlignRight = styled.div`
  text-align: right;
`

export const TextAlignLeft = styled.div`
  text-align: left;
`

export const TextBig = styled.span`
  font-size: 1.25em;
`

export const TextSmall = styled.span`
  font-size: 0.9em;
`
