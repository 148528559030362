import React, { useRef } from 'react'

import { DateTime } from 'luxon'

import {
  StyledPopup,
  InputBox,
  Modal,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
  ModalLabel,
  InputFieldModal,
  TextItem,
} from './CommonStylesForms'

function FormValidatedEmail(props) {
  const credentialForm = useRef(null)
  const dateValidated = DateTime.local()

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(credentialForm.current)

    let attributes = [
      {
        name: 'local_part',
        value: form.get('local_part'),
      },
      {
        name: 'domain',
        value: form.get('domain'),
      },
      {
        name: 'address',
        value: form.get('address'),
      },
      {
        name: 'date_validated',
        value: Math.floor(dateValidated.ts / 1000).toString(),
      },
      {
        name: 'identity_image',
        value: form.get('identity_image'),
        'mime-type': 'text/hashlink',
      },
    ]

    let schema = props.schemas.SCHEMA_VALIDATED_EMAIL
    let schemaParts = schema.split(':')

    let newCredential = {
      connectionID: props.contactSelected.Connections[0].connection_id,
      schemaID: schema,
      schemaVersion: schemaParts[3],
      schemaName: schemaParts[2],
      schemaIssuerDID: schemaParts[0],
      comment: 'Validated Email',
      attributes: attributes,
    }

    props.submitCredential(newCredential, e)
    props.closeCredentialModal()
  }

  function closeModal() {
    props.closeCredentialModal()
  }

  return (
    <StyledPopup
      open={props.credentialModalIsOpen}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <Modal className="modal">
        <ModalHeader>Issue Validated Email</ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            <form onSubmit={handleSubmit} ref={credentialForm}>
              <InputBox>
                <ModalLabel htmlFor="address">Email Address</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Example@gmail.com"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="local-part">Local Part</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="local_part"
                  id="local_part"
                  placeholder=""
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="domain">Domain</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="domain"
                  id="domain"
                  placeholder=""
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="date_validated">Date Validated</ModalLabel>
                <TextItem
                  type="date"
                  name="date_validated"
                  id="date_validated"
                  placeholder=""
                >
                  {dateValidated.toLocaleString(DateTime.DATETIME_SHORT)}
                </TextItem>
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="identity_image">Image Url</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="identity_image"
                  id="identity_image"
                  placeholder=""
                />
              </InputBox>
              <Actions>
                <CancelBtn type="button" onClick={closeModal}>
                  Cancel
                </CancelBtn>
                <SubmitBtnModal type="submit">Submit</SubmitBtnModal>
              </Actions>
            </form>
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={closeModal}>&times;</CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default FormValidatedEmail
