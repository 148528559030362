import React, { useRef } from 'react'

import { DateTime } from 'luxon'

import {
  StyledPopup,
  InputBox,
  Modal,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
  ModalLabel,
  InputFieldModal,
  TextItem,
} from './CommonStylesForms'

function FormPassport(props) {
  const credentialForm = useRef(null)

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(credentialForm.current)

    let attributes = [
      {
        name: 'type',
        value: form.get('type') || '',
      },
      {
        name: 'issuing_country',
        value: form.get('issuing_country') || '',
      },
      {
        name: 'surname',
        value: form.get('surname') || '',
      },
      {
        name: 'given_names',
        value: form.get('given_names') || '',
      },
      {
        name: 'date_of_birth',
        value:
          Math.floor(
            DateTime.fromISO(form.get('date_of_birth')).ts / 1000
          ).toString() || '',
      },
      {
        name: 'date_of_issue',
        value:
          Math.floor(
            DateTime.fromISO(form.get('date_of_issue')).ts / 1000
          ).toString() || '',
      },
      {
        name: 'date_of_expiration',
        value:
          Math.floor(
            DateTime.fromISO(form.get('date_of_expiration')).ts / 1000
          ).toString() || '',
      },
      {
        name: 'authority',
        value: form.get('authority') || '',
      },
      {
        name: 'passport_number',
        value: form.get('passport_number') || '',
      },
      {
        name: 'photo',
        value: '',
      },
      {
        name: 'signature',
        value: '',
      },
      {
        name: 'nationality',
        value: form.get('nationality') || '',
      },
      {
        name: 'endorsements',
        value: form.get('endorsements') || '',
      },
      {
        name: 'sex',
        value: form.get('sex') || '',
      },
      {
        name: 'place_of_birth',
        value: form.get('place_of_birth') || '',
      },
    ]

    let schema = props.schemas.SCHEMA_PASSPORT
    let schemaParts = schema.split(':')

    let newCredential = {
      connectionID: props.contactSelected.Connections[0].connection_id,
      schemaID: schema,
      schemaVersion: schemaParts[3],
      schemaName: schemaParts[2],
      schemaIssuerDID: schemaParts[0],
      comment: 'Passport',
      attributes: attributes,
    }

    props.submitCredential(newCredential, e)
    props.closeCredentialModal()
  }

  function closeModal() {
    props.closeCredentialModal()
  }

  return (
    <StyledPopup
      open={props.credentialModalIsOpen}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <Modal className="modal">
        <ModalHeader>Issue Passport Credential</ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            <form onSubmit={handleSubmit} ref={credentialForm}>
              <InputBox>
                <ModalLabel htmlFor="type">Document Type</ModalLabel>
                <InputFieldModal type="text" name="type" id="type" />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="issuing_country">
                  Issuing Country
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="issuing_country"
                  id="issuing_country"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="surname">Surname</ModalLabel>
                <InputFieldModal type="text" name="surname" id="surname" />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="given_names">
                  First and Middle Names
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="given_names"
                  id="given_names"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="date_of_birth">Date of Birth</ModalLabel>
                <InputFieldModal
                  type="date"
                  name="date_of_birth"
                  id="date_of_birth"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="date_of_issue">Date of Issue</ModalLabel>
                <InputFieldModal
                  type="date"
                  name="date_of_issue"
                  id="date_of_issue"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="date_of_expiration">
                  Date of Expiration
                </ModalLabel>
                <InputFieldModal
                  type="date"
                  name="date_of_expiration"
                  id="date_of_expiration"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="authority">Authority</ModalLabel>
                <InputFieldModal type="text" name="authority" id="authority" />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="passport_number">
                  Passport Number
                </ModalLabel>
                <InputFieldModal
                  type="text"
                  name="passport_number"
                  id="passport_number"
                />
              </InputBox>
              {/* <InputBox>
                <ModalLabel htmlFor="photo">Photo</ModalLabel>
                <InputFieldModal type="text" name="photo" id="photo" />
                {photo}
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="signature">Signature</ModalLabel>
                <InputFieldModal type="text" name="signature" id="signature" />
                {signature}
              </InputBox> */}
              <InputBox>
                <ModalLabel htmlFor="nationality">Nationality</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="nationality"
                  id="nationality"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="endorsements">Endorsements</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="endorsements"
                  id="endorsements"
                />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="sex">Sex</ModalLabel>
                <InputFieldModal type="text" name="sex" id="sex" />
              </InputBox>
              <InputBox>
                <ModalLabel htmlFor="place_of_birth">Place of Birth</ModalLabel>
                <InputFieldModal
                  type="text"
                  name="place_of_birth"
                  id="place_of_birth"
                />
              </InputBox>
              <Actions>
                <CancelBtn type="button" onClick={closeModal}>
                  Cancel
                </CancelBtn>
                <SubmitBtnModal type="submit">Submit</SubmitBtnModal>
              </Actions>
            </form>
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={closeModal}>&times;</CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default FormPassport
